import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { motion, Variants } from "framer-motion";

import { StoryItem } from "./common";
import { StoryListItem } from "./StoryListItem";
import { StoriesListBackground } from "./StoriesListBackground";
import { useIntersectionObserver } from "../../../utils/hooks/intersectionObserver";

import styles from "./StoriesList.module.scss";
import { Circles } from "../../common/Circles";
import { useAppContext } from "../../Context/AppContext";
import { performantUpdate } from "../../../utils/dom";

interface StoriesListProps {
  stories: StoryItem[];
}

const storyVariants = [2, 3, 0];

const variants: Variants = {
  initial: custom => ({
    y: custom ? 0 : "100px",
    opacity: 0,
  }),
  enter: {
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  disappear: {
    opacity: 0,
    transition: {
      duration: 1,
      delay: 1,
    },
  },
  exit: {
    y: "-100px",
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const StoriesList: React.FC<StoriesListProps> = ({ stories }) => {
  const { lastStory, navigationReversing } = useAppContext();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [atEnd, setAtEnd] = useState(false);
  const [docHeight, setDocHeight] = useState(1000);
  const mounted = useRef(true);
  const [bgSection, setBgSection] = useState<number>(0);

  const onIntersect = useCallback<IntersectionObserverCallback>(entries => {
    const intersectingEntries = entries.filter(e => e.isIntersecting);
    const intersecting = intersectingEntries
      .map(e => parseInt((e.target as HTMLElement).dataset.index || "-1", 10))
      .filter(id => id !== -1);

    if (intersecting.length) {
      const max = Math.max(...intersecting);

      if (mounted.current) {
        setActiveIndex(max);
      }
    } else if (intersectingEntries.length) {
      if (intersectingEntries.pop()?.target.classList.contains("end")) {
        if (mounted.current) {
          setAtEnd(true);
        }
      }
    }
  }, []);

  const pages = useMemo(() => {
    const p = Math.ceil((docHeight * 0.9) / 1000);

    return new Array(p).fill(0);
  }, [docHeight]);

  const { setObserverRoot, observeElement } = useIntersectionObserver(
    onIntersect,
    {
      threshold: [0.8],
    }
  );

  const storiesVariants = useMemo(() => {
    return stories.map((s, idx) => {
      return {
        ...s,
        variant: storyVariants[idx % storyVariants.length],
      };
    });
  }, [stories]);

  useEffect(() => {
    const cb = performantUpdate(() => {
      setBgSection(Math.floor((window.pageYOffset / 1000) * 0.8));
    });

    window.addEventListener("scroll", cb, {
      passive: true,
    });

    if (lastStory) {
      const el = document.querySelector(
        `[data-slug="${lastStory}"]`
      ) as HTMLElement;

      if (el) {
        window.scrollTo(0, el.offsetTop);
      }
    } else {
      window.scrollTo(0, 0);
    }

    setObserverRoot();

    return () => {
      mounted.current = false;

      window.removeEventListener("scroll", cb);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemRef = useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        observeElement(el);
      }
    },
    [observeElement]
  );

  const onAnimationComplete = useCallback(() => {
    if (atEnd) {
      window.scrollTo(0, 0);

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (mounted.current) {
            setAtEnd(false);
          }
        });
      });
    }
  }, [atEnd]);

  return (
    <>
      <motion.div
        key="intro-bg"
        variants={variants}
        initial="initial"
        animate={atEnd ? "disappear" : "enter"}
        exit="exit"
        custom={navigationReversing}
      >
        <StoriesListBackground
          target={"main"}
          onHeightChange={hei => setDocHeight(hei)}
        >
          {pages.map((p, idx) => {
            if (bgSection !== idx && bgSection + 1 !== idx) {
              return null;
            }

            return (
              <div
                key={idx}
                style={{ "--index": idx } as React.CSSProperties}
                className={styles.bg}
              >
                {idx % 2 === 0 ? (
                  <Circles
                    circleKey="001"
                    stroke
                    className={classNames(styles.bgCircle, styles.bgCircleOne)}
                  />
                ) : (
                  <>
                    <Circles
                      circleKey="001"
                      stroke
                      className={classNames(
                        styles.bgCircle,
                        styles.bgCircleSix
                      )}
                    />
                    <Circles
                      circleKey="007"
                      stroke
                      className={classNames(
                        styles.bgCircle,
                        styles.bgCircleSeven
                      )}
                    />
                  </>
                )}
                <Circles
                  circleKey="003"
                  stroke
                  className={classNames(styles.bgCircle, styles.bgCircleTwo)}
                />

                <Circles
                  circleKey="004"
                  stroke
                  className={classNames(styles.bgCircle, styles.bgCircleThree)}
                />

                {idx % 3 === 0 && (
                  <Circles
                    circleKey="005"
                    stroke
                    className={classNames(styles.bgCircle, styles.bgCircleFour)}
                  />
                )}

                <Circles
                  circleKey="006"
                  stroke
                  className={classNames(styles.bgCircle, styles.bgCircleFive)}
                />
              </div>
            );
          })}
        </StoriesListBackground>
      </motion.div>
      <motion.div
        key="intro-items"
        variants={variants}
        initial="initial"
        animate={atEnd ? "disappear" : "enter"}
        exit="exit"
        id="items"
        custom={navigationReversing}
        onAnimationComplete={onAnimationComplete}
        className={styles.items}
      >
        {storiesVariants.map((story, idx) => (
          <StoryListItem
            onRef={onItemRef}
            index={idx}
            key={story.slug}
            active={idx === activeIndex || idx === activeIndex - 1}
            past={idx < activeIndex}
            isVisible={Math.abs(idx - activeIndex) < 3}
            {...story}
          />
        ))}

        <div
          className={classNames(`end`, styles.end, { [styles.active]: atEnd })}
          ref={onItemRef}
        >
          Stop.
          <br />
          Take a breath
        </div>
      </motion.div>
    </>
  );
};

export { StoriesList };
