import React, { useCallback, useEffect, useRef, useState } from "react";
import { performantUpdate } from "../../../utils/dom";
import { useDocumentDimensions } from "../../../utils/hooks";

import styles from "./StoriesListBackground.module.scss";

interface StoriesListBackgroundProps {
  target: string;
  onHeightChange?: (height: number) => void;
}

const StoriesListBackground: React.FC<StoriesListBackgroundProps> = ({
  target,
  children,
  onHeightChange,
}) => {
  const { height } = useDocumentDimensions(target);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const heightRef = useRef<number>(0);

  useEffect(() => {
    const cb = performantUpdate(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.setProperty(
          "--parallax-y",
          `${(window.pageYOffset / heightRef.current) * 20}%`
        );
      }
    });

    window.addEventListener("scroll", cb, { passive: true });

    return () => {
      window.removeEventListener("scroll", cb);
    };
  }, []);

  useEffect(() => {
    if (onHeightChange) {
      onHeightChange(height);
    }

    if (wrapperRef.current) {
      wrapperRef.current.style.setProperty(
        "--document-height",
        `${height * 0.9}px`
      );
    }

    heightRef.current = height;
  }, [height, onHeightChange]);

  const onRef = useCallback(
    ref => {
      wrapperRef.current = ref;

      if (ref) {
        ref.style.setProperty("--document-height", `${height * 0.9}px`);
      }
    },
    [height]
  );

  return (
    <div ref={onRef} className={styles.background}>
      {children}
    </div>
  );
};

export { StoriesListBackground };
