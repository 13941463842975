import React, { useMemo } from "react";
import { graphql, Link, PageProps } from "gatsby";

import { SEO } from "../components/common/seo";
import { StoriesList } from "../components/Layout/StoriesList";
import { StoryItem } from "../components/Layout/StoriesList/common";
import { CirclesMap } from "../components/common/Circles";
import { StoryContentNode } from "../types/data";
import { HomePage } from "../components/Layout/HomePage";

interface GoogleSheetsRow {
  node: {
    id: string;
    title: string;
    storyname: string;
    fields: {
      circle: string;
      slug: string;
    };
  };
}

interface DataProps {
  allStoryContent: {
    edges: Array<{
      node: Pick<
        StoryContentNode,
        "circle" | "title" | "name" | "fullSlug" | "slug"
      >;
    }>;
  };
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const stories = useMemo<StoryItem[]>(() => {
    return data.allStoryContent.edges.map(({ node }) => ({
      id: node.fullSlug,
      slug: node.fullSlug,
      circle: node.circle as keyof typeof CirclesMap,
      title: node.title,
    }));
  }, [data.allStoryContent.edges]);

  return (
    <>
      <SEO title="Home" />

      <HomePage stories={stories} />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default IndexPage;

export const query = graphql`
  query HomeQuery {
    allStoryContent {
      edges {
        node {
          circle
          slug
          title
          fullSlug
          name
        }
      }
    }
  }
`;
