import React from "react";
import classNames from "classnames";

import { motion, Variants } from "framer-motion";
import { Circles } from "../../common/Circles";
import { StaggerText } from "../../common/StaggerText";
import { useSwipeable } from "react-swipeable";
import { StoriesListBackground } from "../StoriesList/StoriesListBackground";
import { Touch } from "../../common/Touch";
import { ReactComponent as LogoSvg } from "../../../images/logo.svg";

import styles from "./IntroSequence.module.scss";
import { useAppContext } from "../../Context/AppContext";
import { graphql, useStaticQuery } from "gatsby";

interface IntroSequenceProps {
  onAdvance: () => void;
  intro?: boolean;
}

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.1,
      staggerChildren: 0.5,
      delayChildren: 0.5,
    },
  },
  exit: {
    y: "-100vh",
    transition: {
      duration: 1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { duration: 1.5 } },
};

const bubble: Variants = {
  hidden: { scale: 0 },
  show: { scale: 1, transition: { duration: 1.5, delayChildren: 1 } },
  exit: {
    y: "-20%",
    transition: {
      duration: 1,
    },
  },
};

interface IntroSequenceQueryData {
  row: {
    bubbleone: string;
    bubbletwo: string;
    bubblethree: string;
    bubblefour: string;
    bubblefive: string;
  };
}

const IntroSequence: React.FC<IntroSequenceProps> = ({ onAdvance }) => {
  const data = useStaticQuery<IntroSequenceQueryData>(graphql`
    query IntroSequenceQuery {
      row: googleSheetIntroSequenceRow {
        bubbleone
        bubblethree
        bubbletwo
        bubblefour
        bubblefive
      }
    }
  `);

  const { intro, isTouch } = useAppContext();

  const handlers = useSwipeable({
    delta: 100,
    onSwipedUp: () => {
      onAdvance();
    },
    preventDefaultTouchmoveEvent: true,
  });

  return (
    <>
      <StoriesListBackground target={"#intro-sequence"}>
        <Circles
          circleKey="001"
          stroke
          appear={1000}
          show={intro}
          className={classNames(styles.bgCircle, styles.bgCircleOne)}
        />

        <Circles
          circleKey="003"
          stroke
          appear={1500}
          show={intro}
          className={classNames(styles.bgCircle, styles.bgCircleTwo)}
        />

        <Circles
          circleKey="004"
          stroke
          appear={1500}
          show={intro}
          className={classNames(styles.bgCircle, styles.bgCircleThree)}
        />

        <Circles
          circleKey="005"
          stroke
          appear={1500}
          show={intro}
          className={classNames(styles.bgCircle, styles.bgCircleFour)}
        />

        <Circles
          circleKey="006"
          stroke
          appear={1500}
          show={intro}
          className={classNames(styles.bgCircle, styles.bgCircleFive)}
        />

        <Circles
          circleKey="003"
          stroke
          appear={1500}
          show={intro}
          className={classNames(styles.bgCircle, styles.bgCircleSix)}
        />
      </StoriesListBackground>

      <motion.section
        key="intro-sequence"
        variants={container}
        initial="hidden"
        animate="show"
        exit="exit"
        id="intro-sequence"
        className={classNames(styles.container, { [styles.advanced]: !intro })}
      >
        <div className={classNames(styles.item, styles.logo)}>
          <LogoSvg />
        </div>

        <motion.div
          variants={bubble}
          className={classNames(styles.item, styles.intro)}
        >
          <Circles
            circleKey="001"
            stroke
            fill
            className={styles.circle}
            appear={500}
            stretch
            background
          />
          <motion.div variants={item} className={styles.content}>
            <StaggerText text={data.row.bubbleone.split("\n")} />
          </motion.div>
        </motion.div>

        <motion.div
          variants={bubble}
          className={classNames(styles.item, styles.info)}
        >
          <Circles
            circleKey="001"
            stroke
            fill
            className={styles.circle}
            appear={500}
            background
          />
          <motion.div variants={item} className={styles.content}>
            <StaggerText text={data.row.bubbletwo.split("\n")} />
          </motion.div>
        </motion.div>

        <motion.div
          variants={bubble}
          className={classNames(
            styles.item,
            styles.instructions,
            styles.instructionsOne
          )}
          role="button"
        >
          <Circles
            circleKey="001"
            fill
            className={styles.circle}
            appear={500}
            background
          />
          <motion.div variants={item} className={styles.content}>
            <StaggerText text={data.row.bubblethree.split("\n")} />
          </motion.div>
        </motion.div>

        <motion.div
          variants={bubble}
          className={classNames(
            styles.item,
            styles.instructions,
            styles.instructionsTwo
          )}
          role="button"
        >
          <Circles
            circleKey="003"
            fill
            stroke
            className={styles.circle}
            appear={500}
            background
          />
          <motion.div variants={item} className={styles.content}>
            <StaggerText text={data.row.bubblefour.split("\n")} />
          </motion.div>
        </motion.div>

        <motion.div
          variants={bubble}
          className={classNames(
            styles.item,
            styles.instructions,
            styles.instructionsThree
          )}
          role="button"
        >
          <Circles
            circleKey="006"
            fill
            className={styles.circle}
            appear={500}
            background
          />
          <motion.div variants={item} className={styles.content}>
            <StaggerText text={data.row.bubblefive.split("\n")} />
          </motion.div>
        </motion.div>

        <motion.div
          variants={bubble}
          className={classNames(
            styles.item,
            styles.instructions,
            styles.instructionsFour
          )}
          onClick={() => onAdvance()}
          role="button"
          {...handlers}
        >
          <Circles
            circleKey="006"
            fill
            className={styles.circle}
            appear={500}
            background
          />
          <motion.div variants={item} className={styles.content}>
            {isTouch ? "Scroll down to start" : "Click here to start"}

            <Touch className={styles.touch} />
          </motion.div>
        </motion.div>
      </motion.section>
    </>
  );
};

export { IntroSequence };
