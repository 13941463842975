import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import styles from "./IntroSwipePrompt.module.scss";
import { performantUpdate } from "../../../utils/dom";
import { Icon } from "../../common/Icon";

const IntroSwipePrompt: React.FC = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  const topMove = useMemo(() => {
    return typeof window !== "undefined" ? window.innerHeight / 2 : 300;
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    let timer = setTimeout(() => {
      setShowPrompt(true);
    }, 1500);

    const cb = performantUpdate(() => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setShowPrompt(true);
      }, 5000);

      if (window.scrollY > 50) {
        setShowPrompt(false);
        // cleanup();
      }
    });

    const cleanup = () => {
      window.removeEventListener("scroll", cb);
    };

    window.addEventListener("scroll", cb, { passive: true });

    return () => {
      clearTimeout(timer);
      cleanup();
    };
  }, []);

  return (
    <button
      onClick={() => window.scrollBy({ top: topMove, behavior: "smooth" })}
      className={classNames(styles.prompt, { [styles.show]: showPrompt })}
    >
      <Icon className={styles.icon} name="swipe-arrow" />
      <Icon className={styles.icon} name="swipe-arrow" />
    </button>
  );
};

export { IntroSwipePrompt };
