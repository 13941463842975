import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { StoryItem } from "../StoriesList/common";

import styles from "./HomePage.module.scss";
import { useAppContext } from "../../Context/AppContext";
import { IntroSequence } from "../IntroSequence";
import { StoriesList } from "../StoriesList";
import { IntroSwipePrompt } from "../IntroSequence/IntroSwipePrompt";

interface HomePageProps {
  stories: StoryItem[];
}

const HomePage: React.FC<HomePageProps> = ({ stories }) => {
  const { intro, advanceIntro, navigationReversing } = useAppContext();

  return (
    <>
      <IntroSwipePrompt />
      <motion.section
        initial={navigationReversing ? { x: "-100%" } : {}}
        animate={navigationReversing ? { x: 0 } : {}}
        exit={{ x: "-100%" }}
        transition={{ duration: 1 }}
        className={styles.list}
      >
        <AnimatePresence exitBeforeEnter>
          {intro ? (
            <IntroSequence
              key="intro-sequence"
              onAdvance={() => advanceIntro()}
            />
          ) : (
            <StoriesList key="stories-list" stories={stories} />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
};

export { HomePage };
